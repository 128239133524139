import { Link } from "gatsby"
import * as React from "react"
import { FaTimes } from 'react-icons/fa'
//import { FaPenAlt } from 'react-icons/fa'
//import { FaInfoCircle } from 'react-icons/fa'

const logo = require ("../../../static/satsanga.png")
const config = require("../../../data/siteConfig")
const styles = require ("./Pagenotes.module.css")

class Pagenotes extends React.Component {
    state = {
        showSidebar: false
    }

    closeSidebar = () => {
        
        this.setState({
            showSidebar:  !this.state.showSidebar
            
        })
    }

    render() {
        const sidebarActive = this.state.showSidebar ? 'is-active' : ''
        
        return(           
            <aside id="sidebar" className = {
                `sidebar ${sidebarActive}`
            }>
                <div id="hideSidebar" className={`${styles.hideSidebar}`} > <FaTimes /> Закрыть</div>
                <p></p>
            </aside>      
        )
    }
}
export default Pagenotes;