import * as React from "react"
import { Link } from "gatsby"
import * as PropTypes from "prop-types"
import Navigation from "../Navigation/Navigation"
import Pagenotes from "../Pagenotes/Pagenotes"

const config = require("../../../data/siteConfig")
const styles = require("./Header.module.css")

const Header = (props:any) => {
    const printTitle = props.siteTitle;
    
    return (
      <header>
          <div className={`
                ${styles.headerTop}`
            } >
              <Navigation />
              <Pagenotes />
              <Link className={`
                    ${styles.headerSiteTitle}
                `}
                to={`/${config.siteUrl}`}>
                <h2 >{ printTitle }</h2>
              </Link>
          </div>          
      </header>     
    )  
  }
  
  Header.propTypes = {
    siteTitle: PropTypes.string,
  }
  
  Header.defaultProps = {
    siteTitle: '',
  }
  
  export default Header