import { Link } from "gatsby"
import * as React from "react"
import { FaBook } from 'react-icons/fa'
import { FaPenAlt } from 'react-icons/fa'
import { FaInfoCircle } from 'react-icons/fa'
import { FaBars } from 'react-icons/fa'

const urlJoin = require ('url-join');
const styles = require ("./Navigation.module.css");
const config = require('../../../data/siteConfig');
const logo =  `${urlJoin(config.siteUrl)}/satsanga.png`;  

class Navigation extends React.Component {
    state = {
        showMenu: false
    }

    toggleMenu = () => {
        
        this.setState({
            showMenu: !this.state.showMenu
        })
    }

    render() {
        const menuActive = this.state.showMenu ? 'is-active' : ''
        
        return(
            <div>
                <div id="closeNav" className = {
                    `${styles.closeNav} ${menuActive}`
                    }
                    onClick = {
                    this.toggleMenu.bind(this,'close')
                    }>
                </div>
                <span style = {{ 
                    margin:0
                }}>
                    <button className = {
                        `${styles.navButton}`
                        }
                        style = {{
                            color: 'white',
                            textDecoration: 'none',
                        }}
                        onClick = { this.toggleMenu }>
                        <FaBars />
                    </button>
                </span>
                <div className = {
                    `${styles.mobileNav} ${menuActive}`
                }>
                    <div className = {
                        `${styles.sidebarHead}`
                    }>
                        <img src = {logo}
                            className = "sideLogo"
                            alt = {
                                config.siteTitle
                            }
                        />
                        <Link to={`/${config.siteUrl}`}>
                        <h2>
                            {config.siteTitle}
                        </h2>
                        </Link>
                    </div>
                    <ul>
                        <li><FaBook /><Link to = "/commentary/">Комментарии к Евангелию</Link></li>
                        <li><FaPenAlt /><Link to = "/articles/">Лекции и статьи</Link></li>
                        <li><FaInfoCircle /><Link to="/about/">Об издании</Link></li>
                    </ul>

                </div>
            </div>
        )
    }
}
export default Navigation;