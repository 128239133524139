import * as React from "react"
import * as PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Header from "../Header/Header"
import './Layout.css'

interface Props {
    children: React.ReactNode
}

const Layout = ({ children }: Props) => (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Sample' },
              { name: 'keywords', content: 'sample, something' },
              { name: 'theme-color', content: '#200F65'},
            ]}
            defer={false}>
            <html lang="en" />
          </Helmet>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main>
            {children}
          </main>
        </div>
      )}
    />
  )
  Layout.propTypes = {
    children: PropTypes.node.isRequired,
  }
  
  export default Layout